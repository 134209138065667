import { initSentry } from "sentry";
initSentry();

document.addEventListener("DOMContentLoaded", function () {
  if (window.todesktop) {
    document.getElementById("remember_me_checkbox").checked = true;

  }
});

import "../stylesheets/authentication.scss";
